.home {
  background: #2499ed;
  min-height: 100vh;
  height: 100%;
}
.inputSection {
  padding-top: 5rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
}
.inputBox {
  width: 40rem;
  height: 1.5rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  background-color: #fff;
  color: #212529;
  border: 1px solid #ced4da;
  border-radius: 0.3rem;
}
.inputBox::placeholder {
  color: rgb(0, 0, 0);
  opacity: 30%;
  font-weight: lighter;
}

.customButton {
  width: 2.3rem;
  height: 2.3rem;
  font-size: 1.3rem;
  border: transparent;
  border-radius: 0.3rem;
  color: #2499ed;
  background: transparent;
  position: relative;
  z-index: 99;
  right: 2.3rem;
}
