.mainContainer {
  background: #fff;
  padding: 1rem;
  margin-left: 5rem;
  margin-right: 5rem;
  border: 1px solid #ced4da;
  border-radius: 0.3rem;
}

.mainCard {
  border: 1px solid #b2b2b3;
  border-radius: 0.3rem;
  display: flex;
  justify-content: space-between;
  margin: 1rem;
  margin-right: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  font-size: 0.9rem;
  line-height: 1.3rem;
}

.leftItems {
  flex: 6;
}

.rightItems {
  flex: 6;
}

.title {
  font-size: 1.2rem;
  font-weight: normal;
  color: #2499ed;
}

.author {
  color: rgb(161, 141, 141);
}

.description {
  color: rgb(65, 65, 65);
  font-weight: lighter;
}

.language {
  color: #2499ed;
  font-size: 1rem;
}

.username {
  color: #1773b4;
  font-weight: bold;
}

.contribution {
  display: flex;
  color: rgb(161, 141, 141);
  font-weight: lighter;
}

.contributionSection {
  margin-right: 2rem;
}

.contributionNumber {
  color: #212529;
  font-size: 1.1rem;
  font-weight: normal;
  margin-right: 0.5rem;
}

.updateDate {
  margin-top: 0.8rem;
  color: rgb(65, 65, 65);
  font-weight: lighter;
  display: flex;
  justify-content: flex-end;
}
